/*

.----.  .----.   .--.  .-. .-.    .----..----..-.   .----..---.  .---.  .----. .----. 
| {}  }/  {}  \ / {} \ | |/ /    { {__  | {_  | |   | {_ /  ___}{_   _}/  {}  \| {}  }
| {}  }\      //  /\  \| |\ \    .-._} }| {__ | `--.| {__\     }  | |  \      /| .-. \
`----'  `----' `-'  `-'`-' `-'   `----' `----'`----'`----'`---'   `-'   `----' `-' `-'

*/


(function($){
	$.boakSelector = function (el, options) {
		// To avoid scope issues, use 'base' instead of 'this'
		// to reference this class from internal events and functions.
		var base = this;
		
		// Access to jQuery and DOM versions of element
		base.$el = $(el);
		base.el = el;
		
		// Add a reverse reference to the DOM object
		base.$el.data('boakSelector', base);
		
		base.init = function () {
			base.options = $.extend({}, $.boakSelector.defaultOptions, options);
			
			// Put your initialization code here
		};
		
		// Sample Function, Uncomment to use
		// base.functionName = function(paramaters){
		// 
		// };
		
		// Run initializer
		base.init();
	};
	
	$.boakSelector.defaultOptions = {
	};
	
	$.fn.boakSelector = function (options) {
		return this.each( function () {
			(new $.boakSelector(this, options));
			
			// For some reason the default options weren't being extended so I'll do it manually
			/*if (!options) {
				options = $.boakSelector.defaultOptions;
			}*/
			options = $.extend({}, $.boakSelector.defaultOptions, options);
			
			var $boakElement = $(this);
			
			if ( $boakElement.hasClass('boak-selector-init') ) {
				return;
			}
			
			$boakElement.addClass('boak-selector boak-selector-init boak-selector-closed');
			$boakElement.prepend('<button class="boak-selector-label">' + $boakElement.find('input:checked ~ label').text() + '</button>');
			
			$boakElement.find('.boak-selector-label').on('click', function (e) {
				e.preventDefault();	
				var $boakElementList = $boakElement.find('ul')
				
				$boakElement.toggleClass('boak-selector-open');
				$boakElement.toggleClass('boak-selector-closed');
				
				$boakElementList.slideToggle(function () {
					if ( $boakElement.hasClass('boak-selector-open') ) {
						// Check for height
						var availableSpaceBelow = window.innerHeight - $boakElementList.offset().top;
						
						if ( availableSpaceBelow > 0 ) {
							$boakElementList.css('max-height', availableSpaceBelow + 'px');
						}
						
						if ( availableSpaceBelow < 100 ) {
							$boakElementList.css('top', 'initial');
							$boakElementList.css('bottom', '0');
							$boakElementList.css('max-height', ($boakElement.offset().top - 67) + 'px');
						}
					}
				});
				
				
			});
			
			$boakElement.find('input').on('change', function () {
				$boakElement.find('.boak-selector-label').text( $(this).next('label').text() );
				$boakElement.removeClass('boak-selector-open');
				$boakElement.addClass('boak-selector-closed');
				$boakElement.find('ul').slideUp();
			});
			
			
			
			
			
		});
	};
	
	
	
	

	
})(jQuery);